<template>
    <v-dialog v-model="dialog" width=500>
        <v-card>
            <v-form ref="form" v-model="formValid">

                <v-toolbar
                    color="primary"
                    dark
                    dense
                    extended
                >
                    <v-toolbar-title v-if="nuevo">Nueva cita</v-toolbar-title>
                    <v-toolbar-title v-if="!nuevo">Datos de la cita</v-toolbar-title>
                    <v-spacer/>
                    <v-btn text @click="dialog=false">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>

                    <template v-slot:extension>
                        <v-expand-transition>
                            <v-btn
                                v-if="!nuevo"
                                text
                                @click="dialogCancela()"
                            >
                                Cancelar cita
                            </v-btn>
                        </v-expand-transition>
                    </template>

                </v-toolbar>

                <v-card-text>
                    <div v-if="nuevo">
                        <v-text-field
                            v-model="medico"
                            label="Consuta para"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-text-field
                            v-model="especialidad"
                            label="Especialidad"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-text-field
                            v-model="fechaLargo"
                            label="Fecha"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-select
                            label="Hora"
                            :items="itemsHora"
                            v-model="dat.hora"
                            rounded
                            filled
                            dense
                            :readonly="!nuevo"
                        />
                        <v-text-field
                            v-model.number="dat.cip"
                            rounded
                            filled
                            dense
                            type="number"
                            label="Expediente"
                            append-outer-icon="mdi-magnify"
                            @click:append-outer="dialogBuscaPaciente=true"
                        />
                        <v-text-field
                            v-model="pac.nombreCompleto"
                            label="Nombre"
                            rounded
                            outlined
                            dense
                            readonly
                        />

                        <v-radio-group row v-model="dat.tipo">
                            <v-radio label="Primera Vez" value="1"/>
                            <v-radio label="Subs." value="2"/>
                            <v-radio label="Especial" value="3"/>
                        </v-radio-group>
                        <v-checkbox v-if="nuevo"
                            label="Telemedicina"
                        />

                        <v-textarea
                            v-model="dat.observaciones"
                            label="Observaciones"
                            :rounded="nuevo"
                            :filled="nuevo"
                            dense
                        />

                    </div>

                    <div v-if="!nuevo">
                        <v-text-field
                            v-model="estaCita.citaId"
                            label="Cita"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-text-field
                            v-model="estaCita.medico"
                            label="Consuta para"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-text-field
                            v-model="estaCita.consultorio"
                            label="Consultorio"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-text-field
                            v-model="estaCita.fecha"
                            label="Fecha"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-text-field
                            v-model="estaCita.paciente"
                            label="Paciente"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-radio-group row v-model="estaCita.tipo" readonly>
                            <v-radio label="Primera Vez" :value="1"/>
                            <v-radio label="Subs." :value="2"/>
                            <v-radio label="Especial" :value="3"/>
                        </v-radio-group>

                        <v-textarea
                            v-model="estaCita.observaciones"
                            label="Observaciones"
                            rounded
                            outlined
                            dense
                            readonly
                        />
                        <v-text-field
                            v-model="estaCita.creado"
                            label="Creado"
                            dense
                            readonly
                        />
                        <!--
                            {{ estaCita }}
                        -->
                    </div>

                    <!--

                        {{ citaId }}
                        {{datosMedico}}
                    -->
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="error" @click="cancela" v-if="nuevo">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" @click="guarda" v-if="nuevo">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-form>

            <busca-paciente
                :dialogBuscaPaciente.sync="dialogBuscaPaciente"
                :paciente.sync="dat.cip"
                :todos="true"
            />
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogModal: Boolean,
        nuevo:Boolean,
        citaId:Number,
        datosMedico:Object,
        estaFecha:Object,
        //hora:String
    },
    components:{
        'busca-paciente':()=>import('@/components/hospital/buscaPaciente'),
    },

    data:()=>({
        formValid:false,
        dat:{},
        fechaLargo:'',
        medico:'',
        especialidad:'',
        pac:{},
        itemsHora:[],
        estaCita:{},
        cid:0,
        dialogBuscaPaciente:false,
    }),

    methods:{
        async getCita(){
            //let params={}
            try{
                const req = await this.$http({
                    url:'/consulta/getCita',
                    method:'GET',
                    params:{id:this.cid},
                })
                this.estaCita=req.data.root
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async guarda(){
            let params = {
                cip:this.dat.cip,
                dateField:this.dat.fecha+" "+this.dat.hora,
                medicoCitaField:this.datosMedico.id,
                hourField: this.dat.hora,
                tipo: this.dat.tipo,
                observaciones: this.dat.obervaciones,
            }

                try{
                    const req = await this.$http({
                    url:'/consulta/guardaCita',
                    method:'post',
                    params:params,
                })
                if(req.data.estado == "true"){
                    this.$emit("guardado")
                    this.cid = req.data.root.id
                    this.getCita()
                }else{
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Consulte los datos e intente de nuevo',
                    })
                }
                this.datos=req.data.root
                this.total=req.data.total
                }catch(err){
                    this.$store.dispatch('security/revisaError',err)
                }

            //this.dialog=false
        },

        async cancela(texto){
            let params = {
                id: this.citaId,
                observacionesCancelado: texto
            }
            try{
                const req = await this.$http({
                url:'/consulta/cancelarCita',
                method:'GET',
                params:params,
            })
            if (req.data.estado == "true"){
                this.$emit("guardado")
            }else{
                this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede cancelar',
                        text: 'Consulte los datos e intente de nuevo',
                    })
            }
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async cargaPaciente(paciente){
            try{
                this.loadingPac=true
                let req = await this.$http({
                    url:'/pacientes/listV2',
                    method:'GET',
                    params:{id:paciente}
                })
                this.loadingPac=false
                let datos = req.data
                if (datos.pacienteId){
                    this.pac = datos
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No existe',
                        text: 'No se encuentran registros para el paciente '+paciente,
                    })
                }
            }catch(err){
                this.loadingPac=false
                this.$store.dispatch('security/revisaError',err)
            }
        },

        dialogCancela(){
            this.dialog=false

            this.$swal.fire({
                title: 'Cancelar cita',
                text: 'Observaciones para cancelar esta cita',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Cancelar',
                inputValidator:  (value) => {
                    if (value) {
                        this.$swal.fire({
                            title:'¿Desea cancelar?',
                            text:'Esta acción no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.cancela(value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },
        async revisaConflicto(cip){
            let params = {id:cip, dateField:this.dat.fecha+" "+this.dat.hora}
            try{
                const req = await this.$http({
                    url:'/consulta/revisaConflicto',
                    method:'GET',
                    params:params,
                })
                if (req.data.total > 0){
                    let conf = '<b>Este paciente tiene consultas agendadas ('+req.data.total+') 4 horas antes o despues de la hora solicitada</b><br/><br/>'
                    req.data.datos.forEach(itm=>{
                        conf = conf + itm.hora+'-'+itm.especialidad+' ('+itm.creado+')<br/>'
                    })
                    conf = conf+'<br/>En caso de autorizar, favor de anotar detalles en el campo de observaciones de la cita.'

                    this.$swal.fire({
                        icon: 'info',
                        title: 'Conflito en citas',
                        html: conf,
                    })
                    //console.log(req.data.datos)
                }
                //console.log(req.data)
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (v){
                if(!this.nuevo){
                    this.cid=this.citaId
                    this.getCita()
                }
                if (this.nuevo){
                    let hr = this.estaFecha.hour
                    if (hr<10){
                        hr='0'+hr
                    }
                    if (this.estaFecha.minute<=30){
                        this.itemsHora=[hr+':00',hr+':05',hr+':10',hr+':15',hr+':20',hr+':25', ]
                    }else{
                        this.itemsHora=[hr+':30',hr+':35',hr+':40',hr+':45',hr+':50',hr+':55', ]
                    }
                    this.dat.fecha = this.moment(this.estaFecha.date,).format('DD-MM-YYYY')
                    this.fechaLargo = this.moment(this.estaFecha.date).format('dddd, MMMM DD YYYY')
                    this.medico = this.datosMedico.nombre
                    this.especialidad = this.datosMedico.especialidadNombre

                }
            }
            if (!v){
                this.$refs.form.reset()
            }
        },
        'dat.cip'(val){
            if (val >= 10000099){
                this.cargaPaciente(val)
                this.revisaConflicto(val)
            } else{
                this.pac = {}
            }

        },
    },
}
</script>

<style>

</style>